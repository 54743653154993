import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Frame from "./pages/Frame";
import Frame1 from "./pages/Frame1";
import Frame1Kitchen from "./pages/Frame1Kitchen";
import Frame1Livingroom from "./pages/Frame1Livingroom";
import Frame2 from "./pages/Frame2";
import Frame3 from "./pages/Frame3";
import Frame4 from "./pages/Frame4";
import Frame5 from "./pages/Frame5";
import Frame6 from "./pages/Frame6";
import Frame7 from "./pages/Frame7";
import Frame8 from "./pages/Frame8";
import Frame_step2 from "./pages/Frame_step2";
import Frame_step3 from "./pages/Frame_step3";
import Frame_step4 from "./pages/Frame_step4";
import Frame_step5 from "./pages/Frame_step5";
import Frame_step6 from "./pages/Frame_step6";
import Frame_step7 from "./pages/Frame_step7";
import Frame_step8 from "./pages/Frame_step8";
import Frame_step9 from "./pages/Frame_step9";
import Frame_step10 from "./pages/Frame_step10";
import Frame_step11 from "./pages/Frame_step11";

import Frame9 from "./pages/Frame9";
import Frame10 from "./pages/Frame10";


function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Penat | AI-Помощник для Дизайна Интерьера Онлайн";
        metaDescription =
          "Создавайте идеальный интерьер с помощью искусственного интеллекта от Penat. Простота, скорость и профессионализм.";
        break;
      case "/room/done/bedroom":
        title = "Готовый Дизайн Спальни | Penat AI";
        metaDescription =
          "Идеи для дизайна спальни с помощью искусственного интеллекта. Узнайте, как преобразить свою спальню.";
        break;
      case "/room/done/kitchen":
        title = "Готовый Дизайн Кухни | Penat AI";
        metaDescription =
          "Решения для современного дизайна кухни. Превратите вашу кухню в мечту с Penat AI.";
        break;
      case "/room/done/livingroom":
        title = "Готовый Дизайн Гостиной | Penat AI";
        metaDescription =
          "Профессиональный дизайн гостиной с помощью Penat AI. Удобство и стиль для вашего дома.";
        break;
      case "/saved/":
        title = "Сохраненные Проекты | Penat AI";
        metaDescription =
          "Просмотрите и управляйте своими сохраненными проектами дизайна интерьера.";
        break;
      case "/our-aim/":
        title = "Наша Миссия | Penat AI";
        metaDescription =
          "Узнайте, как Penat меняет подход к дизайну интерьера с помощью искусственного интеллекта.";
        break;
      case "/faq/":
        title = "Вопросы и Ответы | Penat AI";
        metaDescription =
          "Часто задаваемые вопросы о Penat и наши ответы. Узнайте больше о нашем сервисе.";
        break;
      case "/contact/":
        title = "Контакты | Penat AI";
        metaDescription =
          "Свяжитесь с командой Penat для вопросов и предложений.";
        break;
      case "/warranty/":
        title = "Гарантии | Penat AI";
        metaDescription =
          "Мы предоставляем гарантии на качество наших услуг по дизайну интерьера.";
        break;
      case "/company-details/":
        title = "О Компании | Penat AI";
        metaDescription =
          "Подробная информация о компании Penat и наших услугах.";
        break;
      case "/personal/":
        title = "Личный Кабинет | Penat AI";
        metaDescription =
          "Управляйте своими проектами и настройками в личном кабинете Penat.";
        break;
      case "/step/2/":
        title = "Шаг 2 | Penat AI";
        metaDescription =
          "Заполните второй шаг для создания вашего идеального дизайна интерьера.";
        break;
      case "/step/3/":
        title = "Шаг 3 | Penat AI";
        metaDescription =
          "Продолжайте настройку дизайна интерьера с помощью Penat.";
        break;
      case "/step/4/":
        title = "Шаг 4 | Penat AI";
        metaDescription =
          "Настройте ваш проект дальше с Penat AI.";
        break;
      case "/step/5/":
        title = "Шаг 5 | Penat AI";
        metaDescription =
          "Достигайте совершенства на пятом шаге дизайна.";
        break;
      case "/step/6/":
        title = "Шаг 6 | Penat AI";
        metaDescription =
          "Оставайтесь на пути к идеальному интерьеру с Penat AI.";
        break;
      case "/step/7/":
        title = "Шаг 7 | Penat AI";
        metaDescription =
          "Завершите важные настройки для вашего проекта.";
        break;
      case "/step/8/":
        title = "Шаг 8 | Penat AI";
        metaDescription =
          "Заключительные штрихи для вашего дизайна интерьера.";
        break;
      case "/step/9/":
        title = "Шаг 9 | Penat AI";
        metaDescription =
          "Последние шаги для создания идеального интерьера.";
        break;
      case "/step/10/":
        title = "Шаг 10 | Penat AI";
        metaDescription =
          "Финализация вашего проекта дизайна с помощью Penat.";
        break;
      case "/step/11/":
        title = "Шаг 11 | Penat AI";
        metaDescription =
          "Последний шаг: готовый дизайн интерьера от Penat AI.";
        break;
      case "/terms-of-service/":
        title = "Условия Обслуживания | Penat AI";
        metaDescription =
          "Прочтите условия использования сервиса Penat для дизайна интерьеров.";
        break;
      case "/public-offer/":
        title = "Публичная Оферта | Penat AI";
        metaDescription =
          "Информация о публичной оферте для пользователей Penat AI.";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);


  return (
    <Routes>
      <Route path="/" element={<Frame />} />
      <Route path="/saved/" element={<Frame2 />} />
      <Route path="/our-aim/" element={<Frame3 />} />
      <Route path="/faq/" element={<Frame4 />} />
      <Route path="/contact/" element={<Frame5 />} />
      <Route path="/warranty/" element={<Frame6 />} />
      <Route path="/company-details/" element={<Frame7 />} />
      <Route path="/personal/" element={<Frame8 />} />
      <Route path="/step/2/" element={<Frame_step2 />} />
      <Route path="/step/3/" element={<Frame_step3 />} />
      <Route path="/step/4/" element={<Frame_step4 />} />
      <Route path="/step/5/" element={<Frame_step5 />} />
      <Route path="/step/6/" element={<Frame_step6 />} />
      <Route path="/step/7/" element={<Frame_step7 />} />
      <Route path="/step/8/" element={<Frame_step8 />} />
      <Route path="/step/9/" element={<Frame_step9 />} />
      <Route path="/step/10/" element={<Frame_step10 />} />
      <Route path="/step/11/" element={<Frame_step11 />} />
      <Route path="/room/done/bedroom" element={<Frame1 />} />
      <Route path="/room/done/kitchen" element={<Frame1Kitchen />} />
      <Route path="/room/done/livingroom" element={<Frame1Livingroom />} />
      <Route path="/terms-of-service/" element={<Frame9 />} />
      <Route path="/public-offer/" element={<Frame10 />} />
    </Routes>
  );
}
export default App;
